import {
    defineStore
} from 'pinia'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router from '@/router/index'
export const useUser = defineStore({
    id: 'user',
    state: () => ({
        token: getToken()
    }),
    actions: {
        LOGIN_IN(token) {
            setToken(token)
            router.replace('/accountInformation')
        },
        LOGIN_OUT() {
            removeToken()
            router.push('/account/login')
        }
    }
})
