<template>
	<div class="account-con">
		<header class="header w c">
			<div class="contact-box">
				<img class="logo-img" @click="toHome" :src="website.getWebsiteMsg.logo" alt="" />
			</div>
			<em @click="toHome">返回首页</em>
		</header>
		<div class="login-warp c w">
			<div class="content">
				<div class="form">
					<p class="title">参赛者登录</p>
					<em>您可以直接输入手机号和密码登录</em>
					<el-form ref="ruleFormRef" class="z-form" :model="form" label-width="70px" :rules="rules">
						<el-form-item label="手机号" prop="userName" size="large">
							<el-input v-model="form.userName" placeholder="请输入手机号" />
						</el-form-item>
						<el-form-item label="密码" prop="password" size="large">
							<el-input type="password" v-model="form.password" placeholder="请输入密码" />
						</el-form-item>
						<el-form-item label="验证码" prop="code" size="large">
							<el-input v-model="form.code" placeholder="请输入验证码">
								<template #append>
									<el-image class="code" :src="form.img" @click="handleGetCode"></el-image>
								</template>
							</el-input>
						</el-form-item>
						<el-form-item class="login-btn">
							<el-button type="primary" @click="submitForm(ruleFormRef)" :loading="isClick">
								{{isClick?'登 录 中':'登 录'}}
							</el-button>
						</el-form-item>
						<el-form-item>
							<el-button @click="toPage('/account/register')">注 册</el-button>
						</el-form-item>
					</el-form>
					<router-link to="/account/resetAccount" class="tips">忘记密码？<span>找回密码</span></router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		login,
		getCode
	} from '@/api/user'
	import jsencrypt from '@/utils/jsencrypt'
	import {
RouterLink,
		useRouter
	} from 'vue-router'
	import {
		reactive,
		ref
	} from 'vue'
	import {
		useWebsite
	} from "@/pinia/index";
	import {
		useUser
	} from "@/pinia/user";
	const user = useUser()
	const website = useWebsite()

	const router = useRouter()

	const ruleFormRef = ref('')
	const form = reactive({
		userName: '',
		password: '',
		uuid: '',
		img: '',
		code: ''
	})
	const validateMobile = (rule, value, callback) => {
		const phoneReg = /^[1][3456789][0-9]{9}$/
		if (!phoneReg.test(value)) {
			callback(new Error('请输入正确的手机号'))
		} else {
			callback()
		}
	}
	const rules = reactive({
		userName: [{
			required: true,
			validator: validateMobile,
			trigger: 'blur'
		}],
		password: [{
			required: true,
			message: '请输入密码',
			trigger: 'blur'
		}],
		code: [{
			required: true,
			message: '请输入验证码',
			trigger: 'blur'
		}]
	})
	let isClick = ref(false)
	const submitForm = async (formEl) => {
		if (!formEl) return
		await formEl.validate((valid, fields) => {
			if (valid) {
				if (!isClick.value) {
					isClick.value = true
					let formData = JSON.parse(JSON.stringify(form))
					formData.password = jsencrypt.encodeRSA(formData.password)
					delete formData.img
					login(formData).then(res => {
						user.LOGIN_IN(JSON.stringify(res.data))
					}).catch(e => {
						handleGetCode()
						isClick.value = false
					})
				}

			} else {

			}
		})
	}


	function handleGetCode() {
		getCode().then(res => {
			Object.assign(form, res.data)
		})
	}
	handleGetCode()

	function toHome() {
		router.push({
			path: '/'
		})
	}

	function toPage(path) {
		router.push({
			path
		})
	}
</script>

<style lang="scss" scoped>
	.account-con {
		margin-top: - $headerHeight;
		position: relative;
		height: 100vh;
		width: 100vw;
		background: linear-gradient(to bottom, #ffffff 30%, #eed9c3);

		.header {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			em {
				font-style: normal;
				color: $themeColor;
				font-size: 16px;
				cursor: pointer;
			}

			.contact-box {
				position: relative;
				cursor: pointer;

				.logo-img {
					width: 250px;
				}
			}
		}

		.login-warp {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			.content {
				width: 100%;
				background: url('../../assets/account/account-bg.png') no-repeat;
				background-size: 100% 100%;
				height: 540px;
				overflow: hidden;
				border-radius: 20px;
				border: 1px solid $themeColor;

				.form {
					float: right;
					width: 600px;
					background-color: #fff;
					height: 100%;
					padding: 20px 100px;

					.code {
						width: 80px;
					}

					.title {
						font-size: 26px;
					}

					em {
						font-style: normal;
						font-size: 12px;
						color: #AAA;
					}

					.z-form {
						margin-top: 45px;

						.el-button {
							width: 100%;
							border-radius: 30px;
							height: 40px;
							font-size: 16px;
						}

						.login-btn {
							margin-top: 40px;

						}
					}

					.tips {
						color: #333;
						padding-left: 170px;
						text-decoration: none;

						span {
							color: $themeColor;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
</style>
